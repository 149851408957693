<template>
  <div class="d-flex flex-column flex-center flex-column-fluid h-100">
    <div class="d-flex flex-column flex-center text-center p-10">
      <div class="card card-flush w-lg-650px py-5">
        <div class="card-body py-15 py-lg-20">
          <h1 class="fw-bolder fs-2qx text-danger mb-4">
            {{
              $t(
                "Components.LostWave.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h1>
          <template v-if="internalError.description">
            <div
              class="fw-semibold fs-5 mb-3"
              v-html="
                $t(
                  'Components.LostWave.DescriptionWithInternalErrorCode',
                  {},
                  { locale: this.$store.state.activeLang }
                )
              "
            ></div>
            <div class="col">
              <span
                class="badge badge-info mb-2 fs-5 cursor-pointer"
                @click="$root.copyClipboard(internalError.code)"
              >
                {{ internalError.code }}
              </span>
            </div>
            <div class="col">
              <span
                class="badge badge-info mb-3 fs-5 cursor-pointer"
                @click="$root.copyClipboard(internalError.traceId)"
              >
                {{ internalError.traceId }}
              </span>
            </div>
          </template>
          <div class="mb-0">
            <router-link :to="$appHomeUrl" class="btn btn-primary me-2">
              <i class="bi-app-indicator bi"></i>
              {{
                $t(
                  "BaseModelFields.ReturnHome",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link
            >
            <a
              :href="$config.webSites.support"
              class="btn btn-warning"
              target="_blank"
            >
              <i class="bi-people bi"></i>
              {{
                $t(
                  "BaseModelFields.SupportTeam",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</a
            >
          </div>
          <div>
            <div class="browser">
              <div class="controls">
                <i></i>
                <i></i>
                <i></i>
              </div>
              <div class="eye"></div>
              <div class="eye"></div>
              <div class="mouth">
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: "InternalError",
  data() {
    return {
      internalError: {
        description: null,
        code: null,
        traceId: null,
      },
      internalErrorKey: process.env.VUE_APP_SYSTEM_INTERNAL_ERROR_KEY,
    };
  },
  mounted() {
    this.internalError.description = this.$store.state.internalErrorDescription;
    if (String.isNullOrWhiteSpace(this.internalError.description)) {
      this.$router.push(this.$appHomeUrl);
      return;
    }

    var replacedDescription = this.internalError.description.replaceAll(
      `${this.internalErrorKey}:`,
      ""
    );

    this.internalError.code = replacedDescription
      .split("/")[0]
      .replaceAll(" ", "");

    this.internalError.traceId = replacedDescription.split("/")[1];

    this.$root.changeDocumentTitle(
      this.$t("Components.LostWave.Title"),
      {},
      { locale: this.$store.state.activeLang }
    );
    setCurrentPageBreadcrumbs(
      this.$t(
        "Components.LostWave.Title",
        {},
        { locale: this.$store.state.activeLang }
      ),
      [
        {
          name: this.$t(
            "BaseModelFields.Help",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ]
    );
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      // store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      // store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
  },
};
</script>
